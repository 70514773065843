import request from '@/utils/http'

/**
 * 应用列表
 * @param payload
 */
export function app_list(payload) {
    return request({ url: '/Client/index', method: 'post', data: payload })
}

export function lists(payload) {
    return request({ url: '/Advs/lists', method: 'post', data: payload })
}


export function create(payload) {
    return request({ url: '/Advs/add', method: 'post', data: payload })
}


export function del(payload) {
    return request({ url: '/Advs/delete', method: 'post', data: payload })
}


export function info(payload) {
    return request({ url: '/Advs/info', method: 'post', data: payload })
}

export function edit(payload) {
    return request({ url: '/Advs/edit', method: 'post', data: payload })
}


export function getRegion(payload) {
    return request({ url: '/Advs/getRegion', method: 'post', data: payload })
}