<template>
  <div class="main-body">
    <div class="page-attention">
      <p>发布广告：设置广告类型、计划名称、区域设置</p>
    </div>
    <a-divider />
    <a-form :model="formState" autocomplete="off" :label-col="labelCol" :wrapper-col="wrapperCol" @finish="onSubmit">
      <a-tabs type="card" v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="基础信息">
          <a-form-item label="应用" name="app_id">
            <a-select ref="select" v-model:value="formState.app_id" style="width: 120px" :options="appListData" :fieldNames="fieldNames" placeholder="请选择应用" @change="handleAppSelectChange"></a-select>
          </a-form-item>
          <a-form-item label="广告名称" name="advs_name" :rules="[{ required: true, message: '请输入广告名称!' }]">
            <a-input v-model:value="formState.advs_name" placeholder="请输入广告名称" />
          </a-form-item>
          <a-form-item label="关键词" name="keyword" :rules="[{ required: true, message: '请输入关键词!' }]">
            <a-input v-model:value="formState.keyword" placeholder="请输入关键词,如index_banner" />
          </a-form-item>

          <a-form-item label="跳转类型" name="goto_type" :rules="[{ required: true, message: '请选择跳转类型!' }]">
            <a-radio-group v-model:value="formState.goto_type" name="goto_type">
              <a-radio value="1">仅展示</a-radio>
              <a-radio value="2">内部跳转</a-radio>
              <a-radio value="3">外网地址</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="跳转地址" v-if="formState.goto_type == 2">
            <a-input v-model:value="formState.goto_link" placeholder="请输入跳转地址" />
          </a-form-item>
          <a-form-item label="创意类型" name="advs_type" :rules="[{ required: true, message: '请选择创意类型!' }]">
            <a-radio-group v-model:value="formState.advs_type" name="advs_type">
              <a-radio value="1">单图</a-radio>
              <a-radio value="2">多图</a-radio>
              <a-radio value="3">视频</a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="上传图片" v-if="formState.advs_type == 1">
            <CsImage v-model:imgOne="formState.single_image" />
          </a-form-item>

          <a-form-item label="上传图片" v-else-if="formState.advs_type == 2">
            <div class="muti-image-item" v-for="(item, index) in formState.multi_image" style="margin-bottom: 20px" :key="index">
              <a-row>
                <a-col :span="4">
                  <CsImage v-model:imgOne="item.imgUrl" />
                </a-col>
                <a-col :span="20">
                  <a-input style="margin-bottom: 6px" v-model:value="item.imgTitle" placeholder="请输入标题" />
                  <a-input v-model:value="item.imgLink" placeholder="请输入跳转地址" />
                </a-col>
              </a-row>
              <CloseCircleOutlined @click="removeImageItem(item)" class="delete-image-item" />
            </div>
            <a-button type="dashed" block @click="addImageItem">
              <PlusOutlined />
              添加图片
            </a-button>
          </a-form-item>

          <a-form-item label="上传视频" v-else-if="formState.advs_type == 3">
            <CsVideo v-model="formState.video" />
          </a-form-item>
        </a-tab-pane>
        <a-tab-pane key="2" tab="额外信息">
          <a-form-item label="位置类型" name="location_type" :rules="[{ required: true, message: '请选择位置类型!' }]">
            <a-radio-group v-model:value="formState.location_type" name="location_type">
              <a-radio value="1">横幅</a-radio>
              <a-radio value="2">公告</a-radio>
              <a-radio value="3">弹窗</a-radio>
              <a-radio value="4">启动页</a-radio>
              <a-radio value="5">信息流</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="投放对象" name="on_object" :rules="[{ required: true, message: '请选择投放对象!' }]">
            <a-select v-model:value="formState.on_object">
              <a-select-option value="0">全部</a-select-option>
              <a-select-option value="1">这里是用户群体</a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item label="投放时间">
            <a-range-picker v-model:value="formState.times" show-time />
          </a-form-item>
          <a-form-item label="投放区域">
            <a-radio-group v-model:value="formState.advs_region" name="advs_region">
              <a-radio value="0">全部</a-radio>
              <a-radio value="1">自定义</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="投放区域" v-if="formState.advs_region == 1">
            <a-transfer v-model:target-keys="targetKeys" v-model:selected-keys="selectedKeys" :data-source="mockData" :titles="['区域', '已选']" :render="(item) => item.title" :disabled="disabled" />
          </a-form-item>
        </a-tab-pane>
      </a-tabs>
      <a-form-item :wrapper-col="{ span: 7, offset: 3 }">
        <a-button type="primary" html-type="submit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { message } from "ant-design-vue";
import * as Api from "@/views/advs/api";
import { useRouter, useRoute } from "vue-router";
import CsImage from "@/components/UpImage";
import CsVideo from "@/components/UpVideo";
import dayjs from "dayjs";

import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    CsImage,
    CsVideo,
    CloseCircleOutlined,
    PlusOutlined,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const adv_id = route.query.adv_id;
    const formState = reactive({
      advs_name: "",
      location_type: 1,
      on_object: "0",
      goto_type: 1,
      goto_link: "",
      advs_region: 0,
      advs_type: 1,
      single_image: "",
      multi_image: [],
      video: "",
      times: "",
      keyword: "",
      adv_id: adv_id,
      area: "",
      app_id: null,
    });

    const onSubmit = () => {
      if (formState.app_id == null) {
        message.error("请选择应用");
        return;
      }
      formState.area = selectedKeys.value.join(",");
      Api.edit(formState).then((res) => {
        if (res.status === 200) {
          message.success(res.message);
          router.push("/advs/manage/list");
        } else {
          message.error(res.message);
        }
      });
    };
    const getInfo = () => {
      Api.info({ adv_id: adv_id }).then((res) => {
        formState.advs_name = res.data.advs_name;
        formState.advs_type = res.data.advs_type.toString();
        formState.location_type = res.data.location_type.toString();
        formState.on_object = res.data.on_object.toString();
        formState.goto_type = res.data.goto_type.toString();
        formState.goto_link = res.data.goto_link;
        formState.single_image = res.data.single_image;
        formState.multi_image = res.data.multi_image;
        formState.video = res.data.video;
        formState.times = [
          dayjs(res.data.start_time),
          dayjs(res.data.end_time),
        ];
        formState.keyword = res.data.keyword;
        formState.advs_region = res.data.advs_region.toString();
        formState.area = res.data.area;
        selectedKeys.value = res.data.area.split(",");
        targetKeys.value = res.data.area.split(",");
        formState.app_id = res.data.app_id ? res.data.app_id : null;
      });
    };
    const mockData = ref([]);
    const disabled = ref(false);
    const selectedKeys = ref([]);
    const targetKeys = ref([]);
    const appListData = ref();
    const getAppList = () => {
      Api.app_list().then((res) => {
        appListData.value = res.data;
      });
    };
    const getArea = () => {
      Api.getRegion().then((res) => {
        mockData.value = res.data;
      });
    };

    const addImageItem = () => {
      formState.multi_image.push({
        imgUrl: "",
        imgTitle: "",
        imgLink: "",
      });
    };

    const removeImageItem = (item) => {
      if (formState.multi_image.length > 2) {
        let index = formState.multi_image.indexOf(item);

        if (index !== -1) {
          formState.multi_image.splice(index, 1);
        }
        message.success("删除成功");
      } else {
        message.warning("至少保留2项！");
      }
    };
    const fieldNames = { label: "app_name", value: "app_id" };
    const activeKey = ref("1");

    onMounted(() => {
      getInfo();
      getArea();
      getAppList();
    });
    return {
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 7,
      },
      formState,
      activeKey,
      onSubmit,
      mockData,
      selectedKeys,
      disabled,
      targetKeys,
      addImageItem,
      removeImageItem,
      appListData,
      fieldNames,
    };
  },
});
</script>
<style scoped lang="less">
.muti-image-item {
  position: relative;
  .delete-image-item {
    position: absolute;
    top: -0;
    right: -20px;
  }
}
</style>